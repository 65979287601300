import { cloudflareImage } from "lib/cloudflare";
import { getUrl, getAbsoluteUrl, getCategoryUrl, getCanonicalUrl } from "lib/urls";

export const LOGO_URL = "https://www.awardjoy.com/icons/superhero-1024x1024.png";
const LOGO_WIDTH = "1024";
const LOGO_HEIGHT = "1024";
const SOCIAL_URLS = [
  "https://www.facebook.com/awardjoy",
  "https://twitter.com/AwardJoy",
  "https://pinterest.ca/awardjoy",
  "https://www.instagram.com/award_joy/",
];

export function makeBasicSchema() {
  return {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "AwardJoy",
    url: "https://www.awardjoy.com",
    sameAs: SOCIAL_URLS,
    logo: {
      "@type": "ImageObject",
      url: LOGO_URL,
      width: LOGO_WIDTH,
      height: LOGO_HEIGHT,
    },
  };
}

export function makeCategorySchema({ category, quizzes }) {
  return {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "@id": getCanonicalUrl(),
    name: `${category.name} Quizzes &amp; News`,
    url: getAbsoluteUrl(getCategoryUrl(category)),
    itemListElement: quizzes.map((quiz, index) => ({
      "@type": "Article",
      "@id": getAbsoluteUrl(getUrl(quiz)),
      url: getAbsoluteUrl(getUrl(quiz)),
      position: index,
      headline: quiz.name,
      image: cloudflareImage(quiz.image.src, { width: 1200, height: 600, fit: "cover" }),
    })),
  };
}

function clampHeadline(headline) {
  if (headline.length > 110) {
    return headline.substr(0, 107) + "...";
  }
  return headline;
}

export function makeQuizSchema({ quiz, questions }) {
  return {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: clampHeadline(quiz.name),
    description: quiz.introduction,
    image: [
      cloudflareImage(quiz.image.src, { width: 1200, height: 675, fit: "cover" }),
      cloudflareImage(quiz.image.src, { width: 1000, height: 750, fit: "cover" }),
      cloudflareImage(quiz.image.src, { width: 1000, height: 1000, fit: "cover" }),
    ],
    dateModified: quiz.modified,
    datePublished: quiz.created,
    author: [
      {
        "@type": "Person",
        name: quiz.author.handle,
        // url: ?
      },
    ],
    publisher: {
      "@type": "Organization",
      name: "AwardJoy",
      url: "https://www.awardjoy.com",
      sameAs: SOCIAL_URLS,
      logo: {
        "@type": "ImageObject",
        url: LOGO_URL,
        width: LOGO_WIDTH,
        height: LOGO_HEIGHT,
      },
    },
    asssociatedMedia: questions
      .filter((question) => question.image.src)
      .map((question) => ({
        "@type": "ImageObject",
        image: cloudflareImage(question.image.src, { width: 1200, height: 675, fit: "cover" }),
        caption: question.question,
      })),
    isAccessibleForFree: true,
  };
}
