import Footer from "components/Footer";
import Header from "components/Header";
import LargeQuiz from "components/LargeQuiz";
import { Container, MainColumn, RightColumn } from "components/Layout";
import { makeBasicSchema } from "components/Schemas";
import ScrollableQuizzes from "components/ScrollableQuizzes";
import RightSidebar from "components/RightSidebar";
import LeftSidebar from "components/LeftSidebar";
import StackedQuizzes from "components/StackedQuizzes";
import TopCategories from "components/TopCategories";
import { getCategories, getTrendingQuestions, getTrendingQuizzes } from "lib/api";
import Head from "next/head";
import { useRouter } from "next/router";
import theme from "theme";
import { LOGO_URL } from "components/Schemas";

const PUNCHIES = ["I got this.", "What!?", "This is epic", "But whhhyyy", "Not really...", "Haha"];

const pageDescription =
  "Personality &amp; trivia quizzes that will knock your socks off! Come on in, spend some time - and maybe earn some real money!";

function Page({ trendingQuizzes, trendingQuestions, categories }) {
  const router = useRouter();

  return (
    <>
      <style jsx>{`
        .hero {
          margin-bottom: 4px;
          text-align: center;
          padding: 4px;
        }
        .hero h1 {
          font-size: 48px;
          line-height: 48px;
          margin: 24px 0;
        }
        .hero p {
          margin: 24px 0;
          font-size: 14px;
        }
        .hero a {
          color: ${theme.hsl(theme.links)};
        }
        }
      `}</style>
      <Head>
        <title>AwardJoy Quizzes &amp; News</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content={"https://www.awardjoy.com" + router.pathname} />
        <meta property="og:title" content="AwardJoy Quizzes &amp; News" />
        <meta property="og:image" content={LOGO_URL} />
        <meta property="twitter:description" content={pageDescription} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@AwardJoy" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(makeBasicSchema()),
          }}
        />
      </Head>
      <Header categories={categories} />
      <TopCategories categories={categories} />
      <Container>
        <MainColumn>
          <div className="hero">
            <h1>AwardJoy Quizzes</h1>
            <p>
              Personality &amp; trivia quizzes that will bring you joy - and maybe{" "}
              <a href="/win">some real money!</a>
            </p>
          </div>
          {categories.map((category, index) => (
            <div key={category.slug}>
              {trendingQuizzes[index] && (
                <LargeQuiz overlay={PUNCHIES[index]} index={index} quiz={trendingQuizzes[index]} />
              )}
              {category.quizzes.slice(6).length > 0 && (
                <ScrollableQuizzes quizzes={category.quizzes.slice(6, 9)} />
              )}
              <StackedQuizzes quizzes={category.quizzes.slice(0, 6)} />
            </div>
          ))}
        </MainColumn>

        <RightSidebar questions={trendingQuestions} />
      </Container>
      <Footer />
    </>
  );
}

export async function getServerSideProps({ req }) {
  const cached = req.headers["cache-control"] !== "no-cache";
  const { quizzes } = await getTrendingQuizzes({ cached });
  const { questions } = await getTrendingQuestions({ cached });
  const { categories } = await getCategories({ cached });
  return {
    props: {
      key: "home",
      trendingQuizzes: quizzes,
      trendingQuestions: questions,
      categories,
    },
  };
}

export default Page;
