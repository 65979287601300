import { cloudflareImage } from "lib/cloudflare";
import { getUrl } from "lib/urls";
import theme from "theme";

export default function LargeQuiz({ quiz, overlay, index }) {
  return (
    <a href={getUrl(quiz)}>
      <div className="large-quiz">
        <style jsx>{`
          .large-quiz {
            margin-bottom: 6px;
            background: #fff;
            cursor: pointer;
            border-top: 1px solid ${theme.lightBorder};
            border-bottom: 1px solid ${theme.lightBorder};
          }

          .image-container {
            position: relative;
          }
          img {
            width: 100%;
            height: auto;
          }
          .overlay-text {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -100%);
            color: #333;
            font-size: 24px;
            padding: 8px;
            border-radius: 4px;
            background: #fff; /*${theme.hsl(theme.blue)};*/
            font-family: sans-serif; /*"Comic Sans MS", "Noteworthy", sans-serif;*/
          }
          .name {
            padding: 12px 12px 4px;
            font-size: 24px;
            line-height: 120%;
          }
          .more {
            color: #888;
            font-size: 14px;
            padding: 4px 12px 12px;
          }
          @media screen and (min-width: ${theme.breakpoint2}) {
            .large-quiz {
              border: 1px solid ${theme.lightBorder};
            }
          }
        `}</style>
        <div className="image-container">
          <img src={cloudflareImage(quiz.image.src, { width: 1440, height: 540, fit: "cover" })} />
          <div className="overlay-text">{overlay}</div>
        </div>
        <h2 className="name">{quiz.name}</h2>
        <div className="more">{quiz.subline}</div>
      </div>
    </a>
  );
}
