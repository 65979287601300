import { LeftColumn } from "./Layout";
import AdUnit from "./AdUnit";

export default function LeftSidebar({}) {
  return (
    <LeftColumn>
      <AdUnit desktop="awardjoy_sidebar_l1_desk" desktopHeight={600} desktopWidth={160} />
      {/*<GoogleAdUnit slot={LEFT_SIDEBAR_SLOT} minWidth={1352} /> */}
    </LeftColumn>
  );
}
