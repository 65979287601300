import { cloudflareImage } from "lib/cloudflare";
import { getUrl } from "lib/urls";
import theme from "theme";

export default function ScrollableQuizzes({ quizzes }) {
  //
  return (
    <div className="scrollable-quizzes">
      <style jsx>{`
        .scrollable-quizzes {
          cursor: pointer;
          display: flex;
          overflow-x: scroll;
          padding: 8px 0;
          margin-bottom: 6px;
          background: #fff;
          border-top: 1px solid ${theme.lightBorder};
          border-bottom: 1px solid ${theme.lightBorder};
          width: 100%;
        }
        .quiz {
          display: flex;
          flex-direction: column;
          margin-left: 6px;
        }
        .quiz-image {
          height: 100%;
        }
        .quiz-image img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .name {
          font-size: 13px;
          line-height: 120%;
          font-weight: bold;
          padding: 6px 0;
        }
        @media screen and (min-width: ${theme.breakpoint2}) {
          .scrollable-quizzes {
            border: 1px solid ${theme.lightBorder};
          }
        }
      `}</style>
      {quizzes.map((quiz) => (
        <a key={`${quiz.type}-${quiz.slug}`} href={getUrl(quiz)}>
          <div className="quiz">
            <div className="quiz-image">
              <img
                src={cloudflareImage(quiz.image.src, { width: 512, height: 288, fit: "cover" })}
              />
            </div>
            <h3 className="name">{quiz.name}</h3>
          </div>
        </a>
      ))}
    </div>
  );
}
