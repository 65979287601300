import theme from "theme";

export function Container({ children }) {
  return (
    <div className="container">
      <style jsx>{`
        .container {
          max-width: ${theme.containerMax};
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        @media screen and (min-width: ${theme.containerMin}) {
          .container {
            align-items: flex-start;
            flex-direction: row;
          }
        }
      `}</style>
      {children}
    </div>
  );
}

export function MainColumn({ children }) {
  return (
    <div className="main-column">
      <style jsx>{`
        .main-column {
          max-width: ${theme.mainColumnMax};
        }
        @media screen and (min-width: ${theme.containerMin}) {
          .main-column {
            min-width: ${theme.mainColumnMin};
            margin: ${theme.paddingMin};
          }
        }
        @media screen and (min-width: ${theme.containerMax}) {
          .main-column {
            margin: ${theme.paddingMax};
          }
        }
      `}</style>
      {children}
    </div>
  );
}

export function LeftColumn({ children }) {
  return (
    <div className="left-column">
      <style jsx>{`
        .left-column {
          display: none;
        }
        @media screen and (min-width: ${theme.containerMax}) {
          .left-column {
            display: block;
            position: sticky;
            top: 0;
            min-width: ${theme.leftColumnMin};
            max-width: ${theme.leftColumnMax};
            margin: ${theme.paddingMax};
            margin-right: 0;
          }
        }
      `}</style>
      {children}
    </div>
  );
}

export function RightColumn({ children }) {
  return (
    <div className="right-column">
      <style jsx>{`
        .right-column {
          max-width: ${theme.mainColumnMax};
        }
        @media screen and (min-width: ${theme.containerMin}) {
          .right-column {
            position: sticky;
            top: 0;
            min-width: ${theme.rightColumnMin};
            max-width: ${theme.rightColumnMax};
            background: #fff;
            margin: ${theme.paddingMin};
            margin-left: 0;
            border: 1px solid ${theme.lightBorder};
          }
        }
        @media screen and (min-width: ${theme.containerMax}) {
          .right-column {
            margin: ${theme.paddingMax};
            margin-left: 0;
        }
      `}</style>
      {children}
    </div>
  );
}
