import { getCategoryUrl } from "lib/urls";
import theme from "theme";

export default function Categories({ categories }) {
  return (
    <div className="outer">
      <style jsx>{`
        .outer {
          background: #fff;
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
        .inner {
          display: flex;
          justify-content: space-between;
          overflow-x: scroll;
          max-width: ${theme.containerMax};
          margin: 0 auto;
        }
        .category {
          padding: 8px 8px;
        }
      `}</style>
      <div className="inner">
        {categories.map((category) => (
          <a className="category" key={category.slug} href={getCategoryUrl(category)}>
            {category.name}
          </a>
        ))}
      </div>
    </div>
  );
}
