import { cloudflareImage } from "lib/cloudflare";
import { getUrl } from "lib/urls";
import theme from "theme";

export default function StackedQuizzes({ quizzes }) {
  return (
    <div className="stacked-quizzes">
      <style jsx>{`
        .stacked-quizzes {
          display: flex;
          flex-direction: column;
        }
        .quiz {
          display: flex;
          background: #fff;
          margin-bottom: 4px;
          cursor: pointer;
          border-top: 1px solid ${theme.lightBorder};
          border-bottom: 1px solid ${theme.lightBorder};
        }
        .quiz-image {
          flex: 4;
        }
        .quiz-image img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .author {
          display: none;
        }
        .quiz-text {
          flex: 7;
          padding: 8px;
          display: flex;
          flex-direction: column;
        }
        .subline {
          font-size: 13px;
          line-height: 120%;
          font-weight: normal;
          margin: 4px 0;
          color: #999;
        }
        .name {
          font-size: 14px;
          line-height: 120%;
          font-weight: bold;
        }
        @media screen and (min-width: ${theme.breakpoint1}) {
          .quiz-text {
            padding: 16px;
          }
          .author {
            display: flex;
            align-items: center;
          }
          .author-avatar {
            width: 32px;
            height: 32px;
            border-radius: 32px;
            background: #333;
          }
          .handle {
            margin-left: 8px;
            font-size: 13px;
            color: ${theme.hsl(theme.links)};
            text-decoration: underline;
          }
          .name {
            font-size: 20px;
            margin-bottom: 10px;
          }
          .subline {
            font-size: 16px;
            margin-bottom: 10px;
          }
          .category {
          }
        }
        @media screen and (min-width: ${theme.breakpoint2}) {
          .quiz {
            border: 1px solid ${theme.lightBorder};
          }
        }
      `}</style>
      {quizzes.map((quiz) => (
        <a key={`${quiz.type}-${quiz.slug}`} href={getUrl(quiz)}>
          <div className="quiz">
            <div className="quiz-image">
              <img
                src={cloudflareImage(quiz.image.src, { width: 512, height: 288, fit: "cover" })}
              />
            </div>
            <div className="quiz-text">
              <h3 className="name">{quiz.name}</h3>
              <h4 className="subline">{quiz.subline}</h4>
              <div className="author">
                <img
                  className="author-avatar"
                  src={cloudflareImage(quiz.author.image, {
                    width: 128,
                    height: 128,
                    fit: "cover",
                  })}
                />
                <span className="handle">{quiz.author.handle}</span>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}
