import { cloudflareImage } from "lib/cloudflare";
import { getUrl } from "lib/urls";
import theme from "theme";
import { RightColumn } from "./Layout";
import AdUnit from "./AdUnit";

function Question({ question, index }) {
  return (
    <a
      key={`${question.quiz.type}-${question.quiz.slug}`}
      href={getUrl(question.quiz, question.id)}
    >
      <style jsx>{`
        .question-container {
          position: relative;
          display: flex;
          background: #fff;
          margin: 6px 6px 0;
          cursor: pointer;
          border-top: 1px solid ${theme.lightBorder};
          border-bottom: 1px solid ${theme.lightBorder};
        }
        .question-image {
          position: relative;
          flex: 2;
        }
        .question-image img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .question-text {
          border-left: 1px solid #dfdfdf;
          flex: 3;
          background: #fff;
          padding: 12px;
          margin-top: 6px;
          display: flex;
          align-items: center;
        }
        .question-text .question {
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          text-transform: capitalize;
        }
        .counter {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 24px;
          height: 24px;
          color: #fff;
          text-align: center;
          line-height: 24px;
          background: ${theme.hsl(theme.blue)};
        }

        @media screen and (min-width: ${theme.breakpoint1}) {
          .question-text .question {
            font-size: 20px;
          }
        }
        @media screen and (min-width: ${theme.containerMin}) {
          .question-container {
            display: block;
            border: none;
            margin-bottom: 18px;
          }
          .question-text .question {
            font-weight: 500;
            font-size: 18px;
          }
          .question-text {
            padding: 0;
            line-height: 110%;
            border: none;
          }
          .question-image img {
            height: 120px;
          }
        }
      `}</style>
      <div className="question-container">
        <div className="question-image">
          <div className="counter">{index + 1}</div>
          <img
            src={cloudflareImage(question.image.src || question.quiz.image.src, {
              width: 512,
              height: 384,
              fit: "cover",
            })}
          />
        </div>
        <div className="question-text">
          <h3 className="question">{question.question}</h3>
        </div>
      </div>
    </a>
  );
}

export default function RightSidebar({ questions }) {
  const questionsBeforeAd = 2;

  return (
    <RightColumn>
      <div className="sidebar">
        <style jsx>{`
          .heading {
            background: ${theme.hsl(theme.blue)};
            text-align: center;
            padding: 6px;
            margin: 6px;
            color: #fff;
          }
        `}</style>

        <h2 className="heading">Trending Questions</h2>
        {questions.slice(0, questionsBeforeAd).map((question, index) => (
          <Question key={index} question={question} index={index} />
        ))}
        <AdUnit
          desktop="awardjoy_sidebar_r1_desk"
          desktopHeight={600}
          desktopWidth={300}
        />
        {questions.slice(questionsBeforeAd).map((question, index) => (
          <Question
            key={index}
            question={question}
            index={index + questionsBeforeAd}
          />
        ))}
        <AdUnit
          desktop="awardjoy_sidebar_r2_desk"
          desktopHeight={600}
          desktopWidth={300}
        />
      </div>
    </RightColumn>
  );
}
